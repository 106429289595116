<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            page: 1,
            listPage: 3,
        };
    },
    computed: {
        ...mapGetters({
            ListEvent: 'event/ListEvent',
        }),
    },
    created() {
        this.$store.dispatch('event/get_AllEvent');
    },
};
</script>

<template>
    <b-container fluid class="event">
        <b-container class="banner">
            <img
                src="~@/assets/images/event/baner.png"
                class="banner-img"
                alt=""
            />
            <div class="search-bar">
                <div class="title">{{ $t('events') }}</div>
            </div>
        </b-container>
        <b-container>
            <b-row>
                <b-col
                    md="6"
                    lg="4"
                    cols="12"
                    v-for="event in ListEvent.data"
                    :key="`event-${event.event_id}`"
                >
                    <div class="item-carousel">
                        <router-link
                            :to="{
                                name: 'EventDetail',
                                params: {
                                    slug: event.event_slug,
                                    type: 'su-kien',
                                },
                            }"
                        >
                            <div class="event-item">
                                <div class="event-img">
                                    <img :src="event.event_image" alt="" />
                                </div>
                                <div class="event-content">
                                    <h4>{{ event.event_title }}</h4>
                                    <p>
                                        {{ $t('events') }} -
                                        {{
                                            getDateTime(event.event_time_start)
                                        }}
                                    </p>
                                </div>
                                <div class="line-bottom"></div>
                                <div class="box-sub-bg"></div>
                            </div>
                        </router-link>
                        <div class="event-text">
                            <p v-if="event.event_description">
                                {{ event.event_description }}
                            </p>
                            <router-link
                                :to="{
                                    name: 'EventDetail',
                                    params: {
                                        slug: event.event_slug,
                                        type: 'su-kien',
                                    },
                                }"
                            >
                            {{ $t('seeMore') }}
                            </router-link>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12">
                    <div class="pagination">
                        <div
                            v-for="indexPage in listPage"
                            :key="indexPage"
                            class="dot"
                            :class="page == indexPage ? 'dot-active' : ''"
                            @click="page = indexPage"
                        ></div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<style lang="scss">
.event {
    padding: 50px 0;
    .banner {
        position: relative;
        min-height: 300px;
        border-radius: 25px;
        @media (max-width: 575px) {
            max-width: calc(100% - 30px);
        }
        &.container {
            @media (min-width: 1200px) {
                max-width: 1200px;
            }
            @media (min-width: 1500px) {
                max-width: 1400px;
            }
        }
        img.banner-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 25px;
        }
        .search-bar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: max-content;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .title {
                font-family: 'Roboto', sans-serif;
                color: #fff;
                font-size: 40px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 0px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
                @media (max-width: 767px) {
                    font-size: 26px;
                }
            }
        }
    }
    .item-carousel {
        padding: 50px 0px 20px 0px;
        @media (max-width: 767px) {
            padding: 0px 0px 20px 0px;
            margin-top: 20px;
        }
        .event-item {
            width: 100%;
            height: 100%;
            min-height: 350px;
            color: #ffffff;
            position: relative;
            overflow: hidden;
            z-index: 1;
            .event-img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .event-content {
                position: absolute;
                bottom: 0;
                padding: 10px;
                h4 {
                    margin-bottom: 5px;
                    font-size: 27px;
                    font-weight: 800;
                    position: relative;
                    z-index: 2;
                    display: -webkit-box;
                    line-height: 35px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    @media (max-width: 1199px) {
                        font-size: 25px;
                    }
                }
                p {
                    font-style: italic;
                    display: none;
                    margin-bottom: 10px;
                    font-size: 17px;
                    position: relative;
                    z-index: 2;
                    display: -webkit-box;
                    line-height: 25px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-weight: 400;
                    @media (max-width: 1199px) {
                        font-size: 15px;
                    }
                }
                a {
                    float: right;
                    color: #ffffff;
                    font-style: italic;
                    text-decoration: underline;
                    font-size: 18px;
                    font-weight: bold;
                    position: relative;
                    z-index: 2;
                    @media (max-width: 1199px) {
                        font-size: 16px;
                    }
                }
            }
            .line-bottom {
                width: 100%;
                height: 20px;
                background-color: #ffffff;
                position: absolute;
                z-index: 0;
                left: 0;
                bottom: 0;
            }
            .box-sub-bg {
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                max-height: 120px;
                background-image: linear-gradient(to bottom, #003189, #000000);
                opacity: 0.9;
                box-shadow: -5px -30px 30px #003089;
                display: flex;
                align-items: flex-end;
            }
        }
        .event-text {
            padding: 10px;
            p {
                color: #000000;
                display: -webkit-box;
                line-height: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                font-weight: 500;
            }
            a {
                float: right;
                color: #000000;
                font-style: italic;
                text-decoration: underline;
                font-size: 18px;
                font-weight: 500;
                position: relative;
                z-index: 2;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
    }
    .pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 100px;
        @media (max-width: 575px) {
            margin-top: 20px;
        }
        .dot {
            cursor: pointer;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #999999;
            margin: 0px 10px;
            &.dot-active {
                background-color: #363795;
            }
        }
    }
}
</style>
